import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import context and provider
import Header from "../components/header";
import Footer from "../components/footer";
import IconBox from "../components/iconBox";
import PriceBox from "../components/priceBox";
import TranslateMessage from "../components/translateMessage";

// Import icons
import {
  faComment,
  faLeaf,
  faLightbulb,
  faUsers,
  faComments
} from "@fortawesome/free-solid-svg-icons";

import profil from "../../images/Profil_IBO_bis.jpg";

import "../styles/pages/home.scss";

const Home = () => (
  <>
    <Header />
    <section className="section-welcome">
      <div className="section-content">
        <h1 className="page-heading-h1">
          <TranslateMessage translateKey="home.h1" />
        </h1>

        <br />
        <span>
          <TranslateMessage translateKey="home.h2" />
        </span>

        <br />
        <br />
        <br />
        <a className="button-main" href="https://www.facebook.com/695140197584800/posts/995947524170731/" target="_blank" rel="noopener noreferrer">
          <TranslateMessage translateKey="home.h3" />
        </a>
      </div>
    </section>
    <section className="section-approach" id="section-approach">
      <div className="section-content">
        <h2 className="page-heading-h2 subtitle">
          <TranslateMessage translateKey="home.myWork.h2" />
        </h2>
        <p>
          <TranslateMessage translateKey="home.myWork.subtitle" />
        </p>
        <div className="icon-box-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-xl-3">
                <IconBox
                  title={
                    <TranslateMessage translateKey="home.myWork.category.psychoterapie.title" />
                  }
                  subTitle={
                    <TranslateMessage translateKey="home.myWork.category.psychoterapie.subtitle" />
                  }
                  iconName={faLeaf}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-xl-3">
                <IconBox
                  title={
                    <TranslateMessage translateKey="home.myWork.category.supervision.title" />
                  }
                  subTitle={
                    <TranslateMessage translateKey="home.myWork.category.supervision.subtitle" />
                  }
                  iconName={faLightbulb}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-xl-3">
                <IconBox
                  title={
                    <TranslateMessage translateKey="home.myWork.category.zielgrupp.title" />
                  }
                  subTitle={
                    <TranslateMessage translateKey="home.myWork.category.zielgrupp.subtitle" />
                  }
                  iconName={faUsers}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-xl-3">
                <IconBox
                  title={
                    <TranslateMessage translateKey="home.myWork.category.sproochen.title" />
                  }
                  subTitle={
                    <TranslateMessage translateKey="home.myWork.category.sproochen.subtitle" />
                  }
                  iconName={faComments}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-quote">
      <div className="section-content">
        <blockquote>
          "{<TranslateMessage translateKey="home.quote.text" />}"
        </blockquote>
        <div className="author-wrapper" id="section-presentation">
          <span className="author">
            {<TranslateMessage translateKey="home.quote.author" />}
          </span>
          <FontAwesomeIcon icon={faComment} />
        </div>
      </div>
    </section>
    <section className="section-presentation">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-9">
            <h2 className="page-heading-h2 subtitle">
              {<TranslateMessage translateKey="home.presentation.short" />}
            </h2>
            <p>
              {<TranslateMessage translateKey="home.presentation.long" />}{" "}
              <a
                href="http://legilux.public.lu/eli/etat/leg/loi/2015/07/14/n1/jo"
                target="_blank"
                rel="noopener noreferrer"
              >
                {
                  <TranslateMessage translateKey="home.presentation.link.label" />
                }
              </a>
              {". "}
              {<TranslateMessage translateKey="home.presentation.cm.label" />}
              <a
                href="http://www.collegemedical.lu/Fr/Professionnels/default.asp?ABC=B"
                target="_blank"
                rel="noopener noreferrer"
              >
                (CM)
              </a>
              {", "}
              {<TranslateMessage translateKey="home.presentation.lsp.label" />}
              <a
                href="https://www.slp.lu/e-psycholog-fannen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                (SLP)
              </a>
            </p>
            <p>
              {<TranslateMessage translateKey="home.presentation.block1" />}
            </p>
            <p>
              {<TranslateMessage translateKey="home.presentation.block2" />}
            </p>
          </div>
          <div className="col-sm-12 col-md-3">
            <img src={profil} alt="Isabelle BOUCHEZ" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="section-myapproach-therapies">
        <section className="section-myapproach">
          <div className="blue-bg">
            <div className="container">
              <div className="minititle">
                {<TranslateMessage translateKey="home.tarif.header" />}
              </div>
              <h2 className="page-heading-h2">
                {<TranslateMessage translateKey="home.tarif.title" />}
              </h2>
            </div>
          </div>
        </section>
        <section className="section-therapies">
          <div className="container">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <PriceBox
                    price={
                      <TranslateMessage translateKey="home.tarif.price.1.amount" />
                    }
                    title={
                      <TranslateMessage translateKey="home.tarif.price.1.label" />
                    }
                    duration={
                      <TranslateMessage translateKey="home.tarif.price.1.duration" />
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <PriceBox
                    price={
                      <TranslateMessage translateKey="home.tarif.price.2.amount" />
                    }
                    title={
                      <TranslateMessage translateKey="home.tarif.price.2.label" />
                    }
                    duration={
                      <TranslateMessage translateKey="home.tarif.price.2.duration" />
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <PriceBox
                    price={
                      <TranslateMessage translateKey="home.tarif.price.3.amount" />
                    }
                    title={
                      <TranslateMessage translateKey="home.tarif.price.3.label" />
                    }
                    duration={
                      <TranslateMessage translateKey="home.tarif.price.3.duration" />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section className="section-cns">
      <div className="container subtitle text-center">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="page-heading-h2">Information CNS</h2>
            <p>{<TranslateMessage translateKey="home.tarif.warning" />}</p>
          </div>
        </div>
      </div>
    </section>
    <section className="section-legal">
      <div className="container subtitle text-center">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-content">
              <h2 className="page-heading-h2">
                {<TranslateMessage translateKey="home.legal.title" />}
              </h2>
              <p>{<TranslateMessage translateKey="home.legal.text1" />}</p>
              <p>{<TranslateMessage translateKey="home.legal.text2" />}</p>
              <p>{<TranslateMessage translateKey="home.legal.text3" />}</p>
              <p>
                {<TranslateMessage translateKey="home.legal.text4" />}{" "}
                <a
                  className="button-light"
                  href="https://officenationalenfance.lu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ONE
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </>
);

export default Home;
