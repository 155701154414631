import React from "react";
import { Link } from "react-router-dom";

import { MyContext } from "../../context";
import TranslateMessage from "../components/translateMessage";

import logo from "../../images/logo.jpg";

import "../styles/components/menuMobile.scss";

const MenuMobile = () => (
  <MyContext.Consumer>
    {context => {
      const { state, toggleNav, changeLanguage } = context;
      const { language } = state;
      const luButtonClasses = language === "lu" ? "link activ" : "link";
      const frButtonClasses = language === "fr" ? "link activ" : "link";

      return (
        <header className="header">
          <div className="container-fluid">
            <nav className="nav-wrapper">
              <div className="js-nav nav">
                <ul className="nav-list">
                  <li>
                    <Link className="link logo" to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </li>

                  <li>
                    <a className="link" href="/" onClick={toggleNav}>
                      <TranslateMessage translateKey="nav.home" />
                    </a>
                  </li>

                  <li>
                    <a
                      className="link"
                      href="#section-approach"
                      onClick={toggleNav}
                    >
                      <TranslateMessage translateKey="nav.portfolio" />
                    </a>
                  </li>

                  <li>
                    <a
                      className="link"
                      href="#section-presentation"
                      onClick={toggleNav}
                    >
                      <TranslateMessage translateKey="nav.about" />
                    </a>
                  </li>

                  <li>
                    <a
                      className="link"
                      href="#section-addresse"
                      onClick={toggleNav}
                    >
                      <TranslateMessage translateKey="nav.adr_label" />
                    </a>
                  </li>

                  <li className="nav-languages">
                    <button
                      className={luButtonClasses}
                      onClick={changeLanguage}
                      data-language="lu"
                    >
                      LU
                    </button>

                    <span className="nav-divider">/</span>

                    <button
                      className={frButtonClasses}
                      onClick={changeLanguage}
                      data-language="fr"
                    >
                      FR
                    </button>
                  </li>
                </ul>
              </div>
              <button
                className={
                  state.isNavOpen
                    ? "nav-toggler nav-toggler--open"
                    : "nav-toggler"
                }
                type="button"
                aria-label="Toggle navigation"
                onClick={toggleNav}
              >
                <span />
                <span />
                <span />
              </button>
            </nav>
          </div>
        </header>
      );
    }}
  </MyContext.Consumer>
);

export default MenuMobile;
