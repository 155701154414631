import React, { Component } from "react";

import { MyContext } from "../../context";

// Import translations
import * as translationLU from "../../translations/lu";
import * as translationFR from "../../translations/fr";

const translations = {
  lu: translationLU,
  fr: translationFR
};

class TranslateMessage extends Component {
  render() {
    const { translateKey } = this.props;
    const translateKeys = translateKey.split(".");
    let result = null;

    return (
      <MyContext.Consumer>
        {context => {
          const { language } = context.state;

          result = translations[language];
          result = result ? result.default : null;
          Object.values(translateKeys).forEach(key => {
            result = result ? result[key] : null;
          });

          return result;
        }}
      </MyContext.Consumer>
    );
  }
}

export default TranslateMessage;
