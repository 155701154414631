import React from "react";

import logo from "../../images/logo.jpg";

import "../styles/components/footer.scss";

import TranslateMessage from "../components/translateMessage";

const Footer = () => (
  <footer>
    <div className="container" id="section-addresse">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <h4>{<TranslateMessage translateKey="footer.contact.title" />}</h4>
          <p>
            <img src={logo} alt="Logo" className="footer-logo" />
          </p>
          <p> {<TranslateMessage translateKey="footer.contact.h1" />}</p>
          <p> {<TranslateMessage translateKey="footer.contact.adresse" />}</p>
          <p>
            <a href="tel:00352621967484">(+352) 621 967 484</a>
          </p>
          <p>
            <a href="mailto:contact@isabellebouchez-psychologue.lu">
              contact@isabellebouchez-psychologue.lu
            </a>
          </p>
        </div>
        <div className="col-sm-12 col-md-6">
          <h4>{<TranslateMessage translateKey="footer.access.title" />}</h4>
          <p>{<TranslateMessage translateKey="footer.access.l1" />}</p>
          <p>{<TranslateMessage translateKey="footer.access.l2" />}</p>
          <p>{<TranslateMessage translateKey="footer.access.l3" />}</p>
          <p>{<TranslateMessage translateKey="footer.access.l4" />}</p>
          <p>{<TranslateMessage translateKey="footer.access.l5" />}</p>
          <p>{<TranslateMessage translateKey="footer.access.l6" />}</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
