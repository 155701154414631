import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

// Import pages
import Home from "./pages/Home";

// Component Implementation
const Main = () => {
  return (
    <Router basename="/">
      <React.Fragment>
        <Route exact={true} path="/" component={Home} />
      </React.Fragment>
    </Router>
  );
};

export default Main;
