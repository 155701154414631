/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import "../styles/components/priceBox.scss";

class PriceBox extends Component {
  render() {
    const { price, title, duration } = this.props;

    return (
      <div className="pricebox">
        <div className="pricebox-content text-center">
          <div className="title">{title}</div>
          {/* <div className="price">€ {price}</div> */}
          <div className="price">
            <span className="description-item">
              <FontAwesomeIcon icon={faClock} /> {duration}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default PriceBox;
