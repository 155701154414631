import React from "react";

import MenuDesktop from "./menuDesktop";
import MenuMobile from "./menuMobile";

// Import CSS
import "../styles/components/links.css";

const Header = () => (
  <>
    <MenuMobile />
    <MenuDesktop />
  </>
);

export default Header;
