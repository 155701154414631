/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MyContext } from "../../context";

// Import CSS
import "../styles/components/iconBox.css";

class IconBox extends Component {
  render() {
    const { title, iconName, subTitle, type } = this.props;
    const iconBoxClass = type === "white" ? "icon-box white-box" : "icon-box";

    return (
      <MyContext.Consumer>
        {context => (
          <div className={iconBoxClass}>
            {(() => {
              return (
                <>
                  <div className="icon">
                    <FontAwesomeIcon icon={iconName} />
                  </div>
                  <div className="content">
                    <h3 className="content-title">{title}</h3>
                    <p className="content-text">{subTitle}</p>
                  </div>
                </>
              );
            })()}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

export default IconBox;
