import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Import context provider
import { MyProvider } from "./context";

// Import Main component
import Main from "./app/Main";

// Import CSS
import "./app/styles/base.css";
import "./app/styles/layout.css";
import "./app/styles/bootstrap-grid.css";

// Create App component
const App = () => (
  <MyProvider>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </MyProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));

