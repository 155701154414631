import React, { Component } from "react";
import { Link } from "react-router-dom";

import TranslateMessage from "./translateMessage";
import { MyContext } from "../../context";

import logo from "../../images/logo.jpg";

import "../styles/components/menuDesktop.scss";
import "flag-icon-css/sass/flag-icon.scss";

class MenuDesktop extends Component {
  render() {
    return (
      <MyContext.Consumer>
        {context => {
          const { changeLanguageOnDesktopMenu, state } = context;
          const luLngClasses =
            state.language === "lu"
              ? "flag-icon flag-icon-lu activ"
              : "flag-icon flag-icon-lu";
          const frLngClasses =
            state.language === "fr"
              ? "flag-icon flag-icon-fr activ"
              : "flag-icon flag-icon-fr";

          return (
            <header className="menu-desktop">
              <div className="container-fluid">
                <div className="row">
                  <div className="link-logo col-md-3">
                    <Link to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="navigation col-md-7">
                    <ul>
                      <li className="link">
                        <a href="/">
                          <TranslateMessage translateKey="nav.home" />
                        </a>
                      </li>
                      <li className="link">
                        <a href="#section-approach">
                          <TranslateMessage translateKey="nav.portfolio" />
                        </a>
                      </li>
                      <li className="link">
                        <a href="#section-presentation">
                          <TranslateMessage translateKey="nav.about" />
                        </a>
                      </li>
                      <li className="link">
                        <a href="#section-addresse">
                          <TranslateMessage translateKey="nav.adr_label" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="languages col-md-2">
                    <ul>
                      <li>
                        <button>
                          <span
                            className={luLngClasses}
                            onClick={changeLanguageOnDesktopMenu}
                            data-language="lu"
                          />
                        </button>
                      </li>
                      <li>
                        <button>
                          <span
                            className={frLngClasses}
                            onClick={changeLanguageOnDesktopMenu}
                            data-language="fr"
                          />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </header>
          );
        }}
      </MyContext.Consumer>
    );
  }
}

export default MenuDesktop;
